import {Injectable} from "@angular/core";

@Injectable()
export class RolesManager {
    private rolesNames = ["PETITIONER", "CERT_MANAGER", "REF_MANAGER", "WAR_MANAGER", "CLIENT", "ADMIN"];

    build(document: Document) {
        return this.rolesNames.filter(role => {
                let checkbox = document.getElementById(role) as HTMLInputElement;
                return checkbox.checked
        });
    }

}
